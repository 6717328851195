import { UserClaims } from '@/common/auth.types';
import { ServiceCenterLocationConfig } from '../globalmodules/types';

export interface DynamicFulfillmentState {
  initCompleted: boolean;
  userClaims: UserClaims;
  authorizations: { [key: string]: FulfillmentAuthorization };
  sourceSystemConfigs: SourceSystemConfig[];
  programConfigs: ProgramConfig[];
  vendorConfigs: VendorConfig[];
  fulfillmentOptionConfigs: FulfillmentOptionConfig[];
  priceListItems: PriceListItem[];
  fullPriceListConfigs: PriceListConfig[];
  priceListConfigs: { [key: string]: PriceListConfig };
  fulfillmentOptionCategories:
    | {
        0: 'Repair';
        1: 'Replace';
        2: 'Reimbursement';
        3: 'Assessment';
      }
    | { [key: number]: string }
    | any;
  claimStatuses:
    | {
        0: 'None';
        1: 'Approved';
        2: 'Authorized';
        3: 'Closed';
        4: 'Denied';
        5: 'Open';
      }
    | { [key: string]: string }
    | any;
  inventoryStates: InventoryState[];
  inventoryStatePage: InventoryStatePage;
  inventoryPartsMappingStates: InventoryPartsMappingState[];
  fulfillmentAuthWidgetRecords: FulfillmentAuthorizationWidget[];
  fulfillmentAuthSearchPageResult: FulfillmentAuthorizationSearchPageResult;
  fulfillmentAuthFilterLogs: FulfillmentAuthorizationFilterLog[];
  vendorTicketStatusMappings: VendorTicketStatusMapping[];
  orderStatusMappings: OrderStatusMapping[];
  localeConfigs: LocaleConfig[];
  fulfillmentTicketInvoices: Invoice[];
  userRolesPermissions: UserRolesPermissions;
  roles: RoleConfig[];
  permissions: PermissionsConfig;
  userConfigs: UserConfig[];
  equipmentExclusionConfigs: EquipmentExclusionConfig[];
  ancillaryServiceTypeConfigs: AncillaryServiceTypeConfig[];
  programFeesConfigs: ProgramFeesConfig[];
  countryConfigs: CountryConfig[];
  defaultCountryConfig: CountryConfig;
  serviceNetworkFulfillmentOptionTypeConfigs: ServiceNetworkFulfillmentOptionTypeConfig[];
  repairCatalogs: RepairCatalog[];
  repairCatalogItems: { [key: string]: RepairCatalogItemDefinition[] };
}

export interface FulfillmentAuthorization {
  fulfillmentTickets: FulfillmentTicket[];
  source: number;
  status: StatusHistory;
  statusHistory: StatusHistory[];
  offerHistory?: OfferHistory;
  claimData?: ClaimData;
  serviceAddress?: Address;
  id: string;
}

export interface FulfillmentAuthorizationSearchResult {
  sourceSystem: SourceSystem;
  authorizationId: string;
  claimId: string;
  carrierNumber: string;
  fulfillmentOption: FulfillmentOption;
}

export interface FulfillmentAuthorizationSearchPageResult {
  results: FulfillmentAuthorizationSearchResult[];
  pagingToken: string;
}

export interface FulfillmentAuthorizationWidget {
  sourceSystem: SourceSystem;
  id: string;
  claimId: string;
  clientId: string;
  carrierNumber: string;
  fulfillmentOption: FulfillmentOption;
  vendorId: string;
  fulfillmentTicketStatus: FulfillmentTicketStatus;
  flowType: Enums.FlowTypes;
}

export interface ClaimData {
  claimSummary?: ClaimSummary;
  claimedEquipment?: ClaimedEquipment;
  customer?: Customer;
  deductibleInfo?: null;
  cultureCode?: string;
  policyData: PolicyData;
}

export interface PolicyData {
  policyIdentifier: string;
  policyHolder: Customer;
}

export interface ClaimSummary {
  id?: string;
  peril?: number;
  damageType?: number;
  status?: number;
  claimAuthorizationNumber?: string;
  productId?: string;
  communicationsClientId?: string;
  diagnostics?: DiagnosticsInfo;
  channel?: string;
}

export interface DiagnosticsInfo {
  diagnosticId: string;
  triageUrl: string;
}

export interface ClaimedEquipment extends BasicEquipment {}

export interface BasicEquipment extends EquipmentBase {
  sku?: string;
  serviceSku?: string;
  color?: null;
  damageTypes?: DamageType[];
  purchasePrice?: string;
  carrierModel?: string;
  coverageStartDate?: Date;
  coverageEndDate?: Date;
  serialNumber: string;
}

export interface EquipmentBase {
  deviceId?: string;
  deviceKey?: string;
  make?: string;
  model?: string;
  description?: string;
  equipmentType?: number;
  productCode?: string;
  productDescription?: string;
  serialNumber?: string;
}

export interface Customer {
  mobileNumber?: PhoneNumber;
  workNumber?: PhoneNumber;
  addresses?: Address[];
  firstName?: string;
  middleName?: null;
  lastName?: string;
  email?: null;
  primaryPhoneNumber?: PhoneNumber;
}

export interface PhoneNumber {
  number?: string;
}

export interface Address {
  addressType?: number;
  line1?: string;
  line2?: string;
  line3?: null | string;
  region?: string;
  city?: string;
  country?: string;
  postalCode?: string;
}

export interface FulfillmentTicket {
  id?: string;
  createdOnUtc?: Date;
  bookedOfferConfirmationId?: null | string;
  acceptedOfferInfo?: AcceptedOfferInfo;
  status?: Status;
  fulfillmentTicketOrchestrationInfo?: FulfillmentTicketOrchestration;
  receivedDocuments?: null;
  fulfillmentTermsAcceptance?: FulfillmentTermsAcceptance;
  notes?: null;
  claimAuthorizationNumber?: null | string;
  bookOfferAttempts?: BookOfferAttempt[];
  serviceWarranty?: ServiceWarranty;
  invoices: Invoice[];
  orders: Order[];
}

export interface AcceptedOfferInfo {
  offer: Offer;
  acceptedOfferData?: null;
  accepted: boolean;
  acceptedOnUtc: Date;
}

export interface Offer {
  fulfillmentOption?: number;
  locationId?: string;
  name?: string;
  address?: Address;
  primaryPhone?: string;
  geoLatitude?: string;
  geoLongitude?: string;
  distance?: string;
  openHours?: OpenHour[];
  fulfillmentOptionCategory?: number;
  id?: string;
  offerSetId?: string;
  vendorId?: string;
  isLegacy?: boolean;
  deductibleAmount?: number;
  vendorData?: any;
  priceListCostData: PriceListCostData;
  priceListCostDatas: PriceListCostData[];
}

export interface PriceListCostData {
  vendor: string;
  vendorDeviceId: string;
  vendorRepairId: string;
  deviceTypeId: string;
  repairTypeId: string;
  deviceName: string;
  serviceName: string;
  partsCost: number;
  laborCost: number;
  platformFee: number;
  subTotal: number;
  maxTaxValue: number;
  maxTotalAmount: number;
  serviceProvider: string;
  color: string;
  damageType: DamageType;
  isEditing?: boolean;
}

export interface FulfillmentTicketOrchestration {
  instanceId: string;
  parentInstanceId?: null | string;
  name: string;
  children?: FulfillmentTicketOrchestration[];
}

export interface OpenHour {
  day?: number;
  startTime?: string;
  endTime?: string;
}

export interface FulfillmentTermsAcceptance {
  accepted?: boolean;
  acceptedOnUtc?: Date;
}

export interface Status {
  current: number;
  statusHistory: StatusHistory[];
}

export interface StatusHistory {
  status: number;
  modifiedDate: Date;
  modifiedBy?: null | string;
  reason?: string;
}

export interface OfferHistory {
  current?: Current;
  historyItems?: Current[];
}

export interface Current {
  offers?: string[];
  offerSetId?: string;
  programConfigVersion?: number;
  createdOnUtc?: Date;
}

export enum FulfillmentAuthorizationStatus {
  New = 1,
  InProgress = 2,
  Closed = 3,
}

export enum FulfillmentTicketStatus {
  None = 0,
  New = 1,
  InProgress = 2,
  Fulfilled = 3,
  Cancelled = 4,
  Rejected = 5,
  Failed = 6,
  Booked = 7,
}

export interface IStatus<T> {
  current: T;
  statusHistory: Array<IStatusEntry<T>>;
}

export interface IStatusEntry<T> {
  status: T;
  reason: string;
  modifiedDate: string;
}

export interface SourceSystemIdentifier {
  sourceSystem: string;
  claimNumber: string;
}

export interface Event {
  id: string;
  subject: string;
  data: any;
  eventType: string;
  eventTime: string;
}

export interface OfferSetQuery {
  authorizationId: string;
  offerSetId: string;
}

export interface FulfillmentTicketByAuthorizationQuery {
  authorizationId: string;
  fulfillmentTicketId: string;
}

export interface SearchByAuthIdQuery {
  authorizationId: string;
  forceFetch: boolean;
}

export interface FulfillmentTicketOrchestrationDetail {
  name?: string;
  instanceId?: string;
  runtimeStatus?: string;
  input?: any;
  customStatus?: any;
  output?: null;
  createdTime?: Date;
  lastUpdatedTime?: Date;
  historyEvents?: HistoryEvent[];
}

export interface HistoryEvent {
  EventType?: string;
  Timestamp?: Date;
  FunctionName?: string;
  Result?: any;
  ScheduledTime?: Date;
  Name?: string;
  FireAt?: Date;
  OrchestrationStatus?: string;
}

export interface VendorFulfillmentOption {
  FulfillmentOption: number;
  Vendor: string;
}

export interface IConfig {
  id: string;
  _etag: string;
  userAudit?: UserAudit;
}

export interface IConfigHistory extends IConfig {
  history?: IConfig[];
}

export interface FulfillmentOptionConfig extends IConfig {
  fulfillmentOptionCategory?: number;
  fulfillmentOption?: FulfillmentOption;
  allowedPerils: string[];
  isEnabled: boolean;
  impliedCost?: number;
  termsAndConditionsResourceKey?: string;
  userInterfaceSettings: FulfillmentOptionUserInterfaceSettings;
  vendors: Vendor[];
  canForceCancel: boolean;
  defaultSlaDuration: string;
  requireDeclineReason: boolean;
  requiresPriceList: boolean;
  orchestrationName?: string;
}

export interface SlaConfig {
  slaType: SlaType;
  duration: string;
}

export enum SlaType {
  None = 0,
  RepairCompletion,
}

export interface LocationFilterConfig {
  description: string;
  locationRules: LocationRuleConfig[];
}

export interface LocationRuleConfig {
  isIncluded: boolean;
  postalCodes: string[];
  cities: string[];
  regions: string[];
  countries: string[];
  storeIds: string[];
  serviceProviders: string[];
}

export interface BookedRepairVolumeFilterConfig {
  lookBackWindowHours?: number;
  bookedRepairLimit?: number;
}

export interface FulfillmentOptionUserInterfaceSettings extends BaseUserInterfaceSettings {
  descriptionItemsShadow: any[];
}

export interface Vendor {
  name: string;
  serviceLevelAgreements: SlaConfig[];
  locationFilters: LocationRuleConfig[];
  bookedRepairVolumeFilter?: BookedRepairVolumeFilterConfig;
  serviceProviders: string[];
  minimumInventoryLimit: number;
  offerType?: OfferType;
  orchestrationMode?: OrchestrationMode;
  operationsStrategy?: OperationsStrategy;
  orchestrationName?: string;
  canUpdateTicketAuthorizationAmount: boolean;
  requiresPriceList: boolean;
  invoiceStrategy: InvoiceStrategy;
  flowTypeConfigs: FulfillOptionVendorFlowTypeConfig[];
  includeTax: boolean;
}

export interface CustomerLocationRepairFulfillmentOptionVendorConfig extends Vendor {
  allowsForceBooking: boolean;
  appointmentConfig?: AppointmentConfig;
}

export interface RepairFulfillmentOptionVendorConfig extends Vendor {
  appointmentConfig?: AppointmentConfig;
}

export interface SourceSystemConfig extends IConfig {
  sourceSystem: string;
  userInterfaceSettings: UserInterfaceSettings;
  eventForwarding: EventForwarding;
  productMappingAttributes: ProductMappingAttribute[];
  isDeleted: boolean;
  claimDataIncludedInRequestBody: boolean;
  isAutoAuthorized: boolean;
  searchCriteria: SourceSystemSearchCriteria;
}

export interface VendorConfig extends IConfig {
  userInterfaceSettings: VendorConfigUserInterfaceSettings;
  defaultWarrantyPeriod: number;
  serviceProviderConfigs: ServiceProviderConfig[];
  vendorStatusPathConfig?: VendorStatusPathConfig;
  statusMapperStrategy?: string;
  isNotesAllowed: boolean;
  invoicing?: InvoiceSettings;
}

export interface InvoiceSettings {
  allowNullCompanyId: boolean;
}

export interface VendorStatusPathConfig {
  statusPath?: string;
  statusReasonPath?: string;
}

export interface ServiceProviderConfig {
  name: string;
  serviceCenterAdministration: boolean;
  warrantyPeriod: number | null;
  loanerProgramConfig: LoanerProgramConfig;
  bookedRepairVolumeFilterConfig: BookedRepairVolumeFilterConfig;
  inventoryConfig: InventoryConfig;
  category: string;
  allowedServices: AncillaryServiceConfig[];
  fulfillmentOptionConfigs: VendorFulfillmentOptionConfig[];
  billingVendorStrategy: Enums.BillingVendorStrategy;
  billingVendorId: string;
}
export interface ServiceProviderConfigExtended extends ServiceProviderConfig {
  _showDetails?: boolean;
}

export interface VendorFulfillmentOptionConfig {
  fulfillmentOption: FulfillmentOption;
  attributes: { [key: string]: string | boolean | number | string[] | Date};
}

export interface LoanerProgramConfig {
  returnPeriodInDays: number;
  hasLoanerProgram: boolean;
}

export interface InventoryConfig {
  inventoryFilteringEnabled: boolean;
  minimumInventoryThreshold?: number;
  vendorManagedInventory: boolean;
}

export interface VendorConfigUserInterfaceSettings extends BaseUserInterfaceSettings {
  showAdvancedExchangeLowInventoryMessage: boolean;
  descriptionItemsShadow: any[];
}

export interface BaseUserInterfaceSettings {
  label?: string;
  labelFormatted?: string;
  icon?: string;
  descriptionItems: string[];
  settings: {};
}

export interface VendorTicketStatusMapping extends StatusMapping<FulfillmentTicketStatus> {  
}

export interface OrderStatusMapping extends StatusMapping<FulfillmentTicketStatus> {  
}

export interface StatusMapping<TStatus> extends IConfig {
  targetStatus: TStatus;
  vendor: string;
  fulfillmentOption: number;
  sourceStateId: string;  
  targetReason: string;
  serviceNetwork: string;
  documentType: string;
  triggeringWorkflows?: WorkflowDefinition[];
}

export interface UserInterfaceSettings {
  interfaceVersion: string;
  legacyFulfillmentOptionRedirectUri: string;
  acceptedFulfillmentOptionRedirectUri: string;
  hideLocale: boolean;
  hideAuthVoidWarningAlert: boolean;
  showDisclosureForRepair: boolean;
}

export enum FulfillmentOption {
  None = 0,
  MailIn = 1,
  HubAndSpoke = 2,
  DirectedRepair = 3,
  WalkInRepair = 4,
  ComeToYou = 5,
  RepairReimbursement = 6,
  DeviceExchange = 7,
  AdvancedExchange = 8,
  Jump = 9,
  Buyout = 10,
  Mca = 11,
  Upgrade = 12,
  MailInSelfMail = 13,
  MailInCourierCollection = 14,
  RepairInHomeClaimAssessment = 15,
  RepairCarryInMailInClaimAssessment = 16,
  SmallReplacementClaimAssessment = 17,
  LargeReplacementClaimAssessment = 18,
  DoorstepExchange = 19,
  CashReimbursement = 20,
  GiftCardReplacements = 21,
  InStoreReplacement = 22,
  CardLinkReimbursement = 23,
  RepairPledge = 24,
  JustInTimeDirectedRepair = 25,
  SameDayReplacement = 26,
  InHomeAppointment = 27,
  PickUpAndDelivery = 28,
  InHomeProductAssessment = 29,
  MailInProductAssessment = 30,
}

export interface VendorStatusEvent {
  CorrelationId: string;
  Vendor: string;
  Payload: any;
}

export interface UpdateTicketStatusModel {
  authorizationId: string;
  fulfillmentTicketId: string;
  ticketStatus: FulfillmentTicketStatus;
  statusReason?: string;
}

export interface VendorTicketStatusUpdateRequest {
  authorizationId: string;
  fulfillmentTicketId: string;
  status: string;
  statusReason?: string;
  notes: string;
  data: NameValue[];
}

export interface NameValue {
  name: string;
  value: string | number | boolean | object;
}

export interface TicketAuthorizationAmountUpdateRequest {
  authorizationId: string;
  fulfillmentTicketId: string;
  priceListCostDatas: PriceListCostData[];
}

export interface ProgramConfig extends IConfig {
  programConfigVersions?: ProgramConfigVersion[];
  name?: string;
  programValue?: number;
  externalSystem?: string;
  activeVersion?: number;
  performBestReplacementCheck: boolean;
  inventoryThreshold: number;
  productIds?: null | string[];
  userInterfaceSettings: FulfillmentOptionUserInterfaceSettings;
  productId?: string;
  noEligibleFulfillmentOptionsEventDelayInSeconds?: number;
}

export interface ProgramFulfillmentOption {
  name?: string;
  fulfillmentOption?: FulfillmentOption;
  useLegacy?: boolean;
}

export interface Product extends IConfig {
  name: string;
  description: string;
  sourceSystem: string;
  clientId: string;
}

export interface IPriceListConfig<T> extends IConfig {
  fulfillmentOption: FulfillmentOption;
  vendor: string;
  itemsBlobName: string;
  items: T[];
  programIds: string[];
  serviceProviders: string[];
  documentType: string;
  lastModifiedOnUtc: string;
  lastModifiedBy: string;
  priceListItemCount: number;
  deviceTypeIdLookupStrategy: DeviceTypeIdLookupStrategy;
  invoiceTaxStrategy: InvoiceTaxStrategy;
  uploadInProgress: boolean;
  itemVersions: PriceListItemVersion[];
  flowTypes: Enums.FlowTypes[];
}

export enum UploadStatus {
  Submitted = "Submitted",
  InProgress = "InProgress",
  Failed = "Failed",
  Completed = "Completed",
}

export interface PriceListItemVersion {
  itemsId: string;
  itemCount: number;
  statusUtc: string;
  status: UploadStatus;
  userId: string;
  email: string;
}

export interface PriceListItemsKeys {
  manufacturer: string;
  deviceName: string;
  deviceTypeId: string;
}

export enum InvoiceTaxStrategy {
  IncludeTaxOnInvoice = 'IncludeTaxOnInvoice',
  OmitTaxOnInvoice = 'OmitTaxOnInvoice',
}

export enum DeviceTypeIdLookupStrategy {
  Sku = 'Sku',
  ProductDescProductCode = 'ProductDescProductCode',
  MakeModel = 'MakeModel',
  EquipmentType = 'EquipmentType',
  Model = 'Model',
  Make = 'Make',
  EssentialAccessory = 'EssentialAccessory',
  EquipmentTypeEssentialAccessory = 'EquipmentTypeEssentialAccessory'
}

export interface PriceListConfig extends IPriceListConfig<PriceListItem> {}

export interface IPriceListItem {}

export interface PriceListItem extends IPriceListItem {
  manufacturer: string;
  deviceName: string;
  deviceTypeId: string;
  repairTypeId: string;
  serviceName: string;
  partsCost: number;
  laborCost: number;
  platformFee: number;
  subTotal: number;
  maxTaxValue: number;
  maxTotalAmount: number;
  vendorDeviceTypeId: string;
  vendorRepairTypeId: string;
  modelVariantId: string;
  effectiveStartDate: string;
  effectiveEndDate: string | null;
  color: string;
  damageType: DamageType;
}

export interface InsertPriceListItemsRequest<T> {
  priceListConfigId: string;
  fileName: string;
  items: T[];
}

export interface EquipmentFilterCriterion {
  isIncluded: boolean;
  makes: string[];
  models: string[];
  skus: string[];
}

export interface ProgramConfigVersion {
  version?: number;
  fulfillmentOptions: ProgramFulfillmentOptionConfig[];
  filterDefinitions: FilterDefinition[];
  optionSorter?: SorterDefinition;
  fallbackOfferDefinition: FallbackOfferDefinition;
  offerEnhancers: string[];
}

export interface SorterDefinition {
  name: string;
}

export interface FallbackOfferDefinition {
  fulfillmentOption: FulfillmentOption;
  vendor: string;
}

export interface ProgramFulfillmentOptionConfig {
  name: string;
  fulfillmentOption: FulfillmentOption;
  useLegacy: boolean;
  distanceToSearch?: number;
  numberOfRecordsToReturn?: number;
  filterDefinitions: FilterDefinition[];
  equipmentFilterConfigs: EquipmentFilterConfig[];
  serviceProviders: string[];
  receiptRequired: boolean;
  isPartsOrderingEligible: boolean;
  salvageStrategy?: string;
}

export interface EquipmentFilterConfig {
  description: string;
  criteria: EquipmentFilterCriterion;
}

export interface FilterDefinition {
  type: FilterType;
  name: string;
  description: string;
}

export enum FilterType {
  Offers = 1,
  VendorFulfillmentOptions = 2,
  FulfillmentOptions = 3,
  ProgramConfig = 4,
}

export interface Address {
  Address1?: any;
  Address2?: any;
  Address3?: any;
  City?: any;
  State?: any;
  CountryCode?: any;
  PostalCode?: any;
}

export interface Customer {
  FirstName: string;
  MiddleName?: any;
  LastName: string;
  PrimaryPhone: string;
  Email: string;
  Address: Address;
  AgreementNumber?: any;
}

export interface RepairData {
  RepairTicketId: number;
  RepairTicketGid: string;
  AuthorizationDate: Date;
  AuthorizationNumber: string;
  VendorId: string;
  VendorLocationId: number;
  VendorLocationGid: string;
  ProviderName: string;
  RepairStatus: LegacyRepairStatus;
  RepairReason?: any;
  InProgressDateTime?: any;
  FulfilledDateTime?: any;
  Duration: number;
}

export interface DeviceData {
  Type: string;
  SerialNumber: string;
  Imei?: any;
  Make: string;
  Model: string;
  DeviceTypeId?: any;
  RepairTypeId?: any;
}

export interface LegacyClaimData {
  id: string;
  ClaimNumber: string;
  Customer: Customer;
  RepairData: RepairData;
  DeviceData: DeviceData;
}

export interface LegacyCallError {
  Message: string;
  ErrorDateTime: Date;
  Request: string;
}

export interface LegacyCallData {
  id: string;
  AuthorizationNumber: string;
  Program: string;
  OperationName: string;
  CallDate: Date;
  Error?: LegacyCallError;
}

export enum LegacyRepairStatus {
  Fulfilled = 0,
  InProcess = 1,
  Cancel = 2,
  Reject = 3,
  Created = 4,
}

export enum SourceSystem {
  Eprism = 1,
  Elita = 2,
  Huxley = 3,
  Fixt = 5,
  Lori = 6,
  EIP = 7,
  Verizon = 8,
}

export interface InventoryState extends IConfig {
  manufacturer: string;
  vendorName: string;
  vendorDeviceTypeId: string;
  vendorRepairTypeId: string;
  color: string;
  id: string;
  locationInventoryData: LocationInventory[];
}

export interface LocationInventory {
  id: string;
  name: string;
  inventoryParts: InventoryPart[];
}

export interface InventoryPart {
  partType: InventoryPartType;
  count: number;
  deviceSkus: string[];
}

export enum InventoryPartType {
  None = 0,
  Screen = 1,
  Battery = 2,
  ChargingPort = 3,
  Speaker = 4,
  ServicePack = 5,
  FrontCamera = 6,
  RearCamera = 7,
}

export interface InventoryStatePage {
  inventoryStates: InventoryState[];
  pagingToken: string;
}

export interface InventoryPartsMappingState extends IConfig {
  serviceProvider: string;
  partSku: string;
  partName: string;
  partType: string;
  deviceTypeId: string;
  repairTypeId: string;
  color: string;
  deviceSkus: string[];
  lastUpdatedDate: string | null;
}

export interface JustInTimeInventoryPartsMappingState extends IConfig {
  serviceProvider: string;
  deviceTypeId: string;
  repairTypeId: string;
  partSkus: string[];
  externalPartSkus: string[];
  lastUpdatedDate: string | null;
}

export enum DamageType {
  None = 0,
  CrackedScreen = 1,
  Water = 10,
  Irretrievable = 11,
  BackCover = 12,
  Battery = 13,
  Charging = 14,
  EarpieceMicrophone = 15,
  FrontCamera = 16,
  HomeButton = 17,
  Speaker = 18,
  FrontCover = 19,
  BackCamera = 20,
  Catastrophic = 21,
  Software = 22,
  Network = 23,
  AcceptedDeferredDamage = 24,
  Vibrator = 25,
  Diagnostic = 26,
  Cosmetic = 27,
  Earpiece = 28,
  Microphone = 33,
  PowerFault = 34,
  Bluetooth = 35,
  PowerButton = 36,
  SignalFault = 37,
  SimCard = 38,
  VolumeButton = 39,
  BiometricID = 40,
  Button = 41,
  CrackedScreenAndBackCover = 42,
  Keyboard = 43,
  Other = 999,
}

export enum Peril {
  None = 0,
  Theft = 1,
  Lost = 2,
  AccidentalDamage = 4,
  MechanicalBreakdown = 5,
  PremiumRefund = 6,
  ProductReimbursement = 7,
  BenefitReimbursement = 8,
  StainRemoval = 9,
  PreventativeMaintenance = 10,
}

export enum EquipmentType {
  MobileEquipment = 1,
  Appliance = 2,
  GenericGadget = 3,
  AppleWatch = 4,
  Camera = 5,
  CameraLens = 6,
  DesktopComputer = 7,
  DigitalCamera = 8,
  EReader = 9,
  FitnessHealthGadgets = 10,
  GameConsole = 11,
  Headphones = 12,
  iMac = 13,
  iPhone = 14,
  iPad = 15,
  Laptop = 16,
  MacBook = 17,
  iPod = 18,
  Monitor = 19,
  PDA = 20,
  Navigation = 21,
  SmartWatch = 22,
  Tablet = 23,
  Television = 24,
  VideoCamera = 25,
  VRHeadset = 26,
  AppleAccessories = 27,
  AudioAndDvd = 28,
  CoffeeMachines = 29,
  ComputerAccessories = 30,
  ComputerEquipment = 31,
  HeatingCooling = 32,
  HomeEntertainment = 33,
  Kitchen = 34,
  PersonalComfort = 35,
  RefrigeratorsFreezers = 36,
  Ridables = 37,
  SmallAppliances = 38,
  SmallElectronics = 39,
  WashersDryers = 40,
  Wearables = 41,
  PrintingImaging = 42,
  HomeEquipment = 100,
}

export enum ClaimStatuses {
  None = 0,
  Approved = 1,
  Authorized = 2,
  Closed = 3,
  Denied = 4,
  Open = 5
}

export interface OrchestrationTimeLine {
  id: string;
  timeStamp: Date;
  title: string;
  status: string;
  input: any;
  result: any;
  icon_status: string;
  controls: any[];
  details: string | null;
}

export interface FilterAction {
  filterType: string;
  filterName: string;
  filteredObjectNames: any[];
}

export interface FulfillmentAuthorizationFilterLog {
  offerSetId: string;
  authorizationId: string;
  filterActions: FilterAction[];
  id: string;
}

export enum UserTier {
  Default,
  SuperUser,
  WebUser,
}

export interface AppConfig {
  key: string;
  value: string;
  lastModified: string;
  settings: AppConfigSettings;
}

export interface AppConfigSettings {
  prefix: string;
  sentinelKey: string;
  sentinelKeys: string[];
  keySettings: AppConfigKeyValueSettings;
  valueSettings: AppConfigKeyValueSettings;
}

export interface AppConfigKeyValueSettings {
  label: string;
  helpText: string;
  validator: string;
}

export interface ServiceCentralServiceNetworkConfig {
  key: string;
  fulfillmentOption: FulfillmentOption;
  programId: string;
  programName: string;
  serviceProvider: string;
  serviceNetwork: string;
  lastModified: string;
}

export interface JITSettings {
  eligible: boolean;
  transitDays: number;
}

export enum OfferType {
  None = 0,
  Legacy = 1,
  LegacyWithData = 2,
  DynamicFulfillment = 3,
  ConvertToLegacyWithinOrchestration = 4,
}

export enum OrchestrationMode {
  None = 0,
  Orchestrated = 1,
  ConvertToLegacy = 2,
  NonIntegrated = 3,
}

export interface EventForwarding {
  enabled: boolean;
  forwardingEndpoint: string;
}

export interface LocaleConfig extends IConfig {
  id: string;
  resources: any[];
}

export interface BookOfferAttempt {
  attemptedOnUtc: Date;
  isSuccess: boolean;
  message: string;
}

export interface ServiceWarranty {
  startDate: Date;
  endDate: Date;
  warrantyDays: number | null;
}

export interface OperationsStrategy {
  name: string;
}

export interface UserAudit {
  historyItems: Audit[];
  current: Audit;
}

export interface Audit {
  userId: string;
  name: string;
  modifiedOnUtc: string;
  configAction: string;
}

export interface AppointmentConfig {
  daysToLookOut: number;
  autoCancelMissedAppointments: boolean;
  daysUntilAutoCancel?: number;
}

export interface Invoice extends InvoiceBase {
  id: string;
  receivedOnUtc: string;
  invoiceStatus: string;
  vendor: string;
  costSummary: InvoiceCostSummary[];
}

export interface InvoiceLineItem {
  itemId: string;
  itemName: string;
  itemDescription: string;
  sku: string;
  quantity: string;
  unitPrice: string;
  unitTax: string;
  subTotal: string;
  tax: string;
  total: string;
}
export interface InvoiceCostSummary {
  itemId: string;
  subtotal: string;
  total: string;
  tax: string;
}

export interface InvoiceAttributes {}

export interface InvoiceCompany {
  id: string;
  firstReminderDate: string;
  address: string;
}

export interface InvoiceBase {
  invoiceNumber: string;
  referenceId: string;
  orderId: string;
  invoiceDate: string;
  currency: string;
  subtotal: string;
  tax: string;
  total: string;
  lineItems: InvoiceLineItem[];
  notes: string;
  dueDate: string;
  firstReminderDate: string;
  company: InvoiceCompany;
  attributes: any;
}

export interface ProductMappingAttribute {
  name: string;
  path: string;
  isRequired: boolean;
  weight: number;
  type: string;
  values: string[];
}

export interface SourceSystemClaimData {
  data: string;
  claimDateTime: Date;
  sourceSystem: SourceSystem;
  claimNumber: string;
}

export interface UserRolesPermissions extends IConfig {
  name: string;
  roles: string[];
  permissions: string[];
}

export interface RoleConfig extends IConfig {
  permissions: string[];
  description: string;
}

export interface PermissionsConfig extends IConfig {
  categories: Permission[];
}

export interface Permission {
  category: string;
  permissions: string[];
}

export interface UserConfig extends IConfig {
  name: string;
  roles: string[];
  permissions: any[];
}

export interface RouteAction {
  name: RoutingAction;
  action?: string;
  route?: string;
  query?: any;
  params?: any;
  requiredPermission: string;
  exclusion?: (id: string) => boolean;
}

export enum RoutingAction {
  Create = 'Create',
  Copy = 'Copy',
  Edit = 'Edit',
  Fetch = 'Fetch',
  Remove = 'Remove',
  Upload = 'Upload',
  Download = 'Download',
  Refresh = 'Refresh',
  History = 'History',
  Revert = 'Revert',
  RemovedItems = 'RemovedItems',
  Delete = 'Delete',
}

export enum OrchestrationRuntimeStatus {
  Unknown = -1,
  Running = 0,
  Completed = 1,
  ContinuedAsNew = 2,
  Failed = 3,
  Canceled = 4,
  Terminated = 5,
  Pending = 6,
  Suspended = 7,
}

export interface EquipmentExclusionConfig extends IConfig {
  fulfillmentOption: FulfillmentOption;
  programIds: string[];
  items: EquipmentExclusionItem[];
  name: string;
  vendor?: string;
}

export interface EquipmentExclusionItem extends IConfig {
  equipmentExclusionConfigId: string;
  equipmentIdentifier: string;
  equipmentIdentifierLookupStrategy: DeviceTypeIdLookupStrategy;
  damageTypes: string[];
  reason: string;
  startDate: string;
  endDate?: string;
}

export enum EquipmentExclusionReason {
  Backordered = 'Backordered', 
  Recall = 'Recall', 
  Unrepairable = 'Unrepairable',
}

export interface ServiceProviderInfo {
  id: string;
  providerName: string;
  address: Address;
  phoneNumber: PhoneNumber;
  email: string;
}

export interface Order {
  orderId: string;
  vendor: string;
  serviceProviderInfo?: ServiceProviderInfo;
  externalOrderId: string;
  currency: string;
  subtotal: string;
  tax: string;
  total: string;
  status: OrderStatus;
  lineItems: OrderLineItem[];
  shipments: Shipment[],
  category: Enums.OrderCategory;
  serviceType: string;
  billingVendorId: string;
  serviceNetwork: string;
}

export interface OrderStatus {
  current: number;
  statusHistory: OrderStatusHistory[];
}

export interface OrderStatusHistory extends StatusHistory {
  notes: string;
  data: string;
}

export interface OrderLineItem {
  itemType: string;
  description: string;
  subTotal: string;
  tax: string;
  total: string;
}

export enum OrderStatusEnum {
  None = 0,
  Pending = 1,
  InProgress = 2,
  Closed = 3,
  Cancelled = 4,
  Rejected = 5,
  Failed =6,
  Created = 7,
}

export enum InvoiceStrategy {
  None = 'None',
  StatusUpdates = 'StatusUpdates',
  InvoiceApi = 'InvoiceApi',
}

export interface Shipment {
  courier: string;
  courierMode: string;
  shipmentType: string;
  trackingNumber: string;
  labelUrl: string;
  status: string;
  labelCreateTime: Date;
}

export namespace Enums {  
  export enum OrderStatus {
    None = 0,
    Pending = 1,
    InProgress = 2,
    Closed = 3,
    Cancelled = 4,
    Rejected = 5,
    Failed = 6,
    Created = 7
  }

  export enum OrderCategory {    
    Primary = 'Primary',
    Ancillary = 'Ancillary'
  }

  export enum BillingVendorStrategy {
    ServiceProviderId = 'ServiceProviderId',
    ServiceNetwork = 'ServiceNetwork',
    ServiceCenter = 'ServiceCenter'
  }

  export enum FlowTypes {  
    Default = "Default",
    ServiceWarranty = "ServiceWarranty",
    EssentialAccessory = "EssentialAccessory",
  }
}

export interface WorkflowDefinition{
  name: string;
  data?: { [key: string]: string };
}

export interface AncillaryServiceConfig {
  serviceType: string;
  attributes: { [key: string]: string | boolean | number | string[] | Date};
}

export interface IHasAllowedAttributes {
  id: string;
  allowedAttributes: AllowedAttribute[];
}

export interface AncillaryServiceTypeConfig extends IHasAllowedAttributes, IConfig {
  serviceType: string;
  isEdit?: boolean;
}

export interface ServiceNetworkFulfillmentOptionTypeConfig extends IHasAllowedAttributes, IConfig {
  isEdit?: boolean;
  fulfillmentOption: FulfillmentOption;
}

export interface AllowedAttribute {
  key: string;
  displayName: string;
  isRequired: boolean;
  allowMultiple: boolean;
  dataType: string;
  defaultValue: any;
  description: string;
  allowedValues: any[];
}

export interface ProgramFeesConfig extends IConfig {
  programId: string;
  fulfillmentOption: FulfillmentOption;
  feeHistory: ProgramFeeEntry[];
}

export interface ProgramFeeEntry {
  id: string;
  fees: FeeAmount[];
  effectiveStartDate: string;
  effectiveEndDate: string;
}

export interface FeeAmount {
  feeType: FeeType;
  proposedAmount: number;
  actualAmount: number; 
}

export interface FeeType {
  id: string;
  interval: string;
}

export enum ConfigType {
  None = 'None',
  FulfillmentOptions = 'FulfillmentOptions',
  Vendors = 'Vendors',
  SourceSystems = 'SourceSystems',
  Products = 'Products',
  Programs = 'Programs',
  PriceLists = 'PriceLists',
  VendorTicketStatus = 'VendorTicketStatus',
  OrderStatus = 'OrderStatus',
  Locales = 'Locales',  
  UserRolesPermissions = 'UserRolesPermissions',
  Roles = 'Roles',
  Users = 'Users',
  EquipmentExclusionConfig = 'EquipmentExclusionConfig',
  EquipmentExclusionItems = 'EquipmentExclusionItem',
  ProgramFees = 'ProgramFees',
  RepairCatalogDefinition = 'RepairCatalogDefinition',
}

export interface AllowedValue extends NameValue {
  value: string;
}

export interface AddressComponent {
  required: boolean;
  validationRegex?: string;
  allowedValues: AllowedValue[];
  googlePropertyName?: string;
}

export interface AddressComponents {
  line1: AddressComponent;
  line2: AddressComponent;
  line3: AddressComponent;
  city: AddressComponent;
  region: AddressComponent;
  postalCode: AddressComponent;
}

export interface CountryConfig extends IConfig {
  countryCodeIso2: string;
  countryCodeIso3: string;
  countryName: string;
  currencyCode: string;
  currencySymbol: string;
  currencyName: string;
  defaultLanguageCode: string;
  distanceType: string;
  addressComponents: AddressComponents;
}

export interface SourceSystemSearchCriteria {
  criteria: SourceSystemCriteria[];
  delimiter: string;
}

export interface SourceSystemCriteria {
  name: string;
  values: string[];
  defaultValue?: string;
}

export enum Environments {
  LocalHost = 'LocalHost',
  Development = 'Development',
  Model = 'Model',
  Production = 'Production',
}

export interface AccessRequest {
  roles: string[];
  reason: string;
  environment: string;
  region: string;
}

export interface SearchCriteria extends NameValue {
  value: string;
}

export interface FulfillOptionVendorFlowTypeConfig {
  flowType: Enums.FlowTypes;
  requiresPriceList: boolean;
}

export interface RepairCatalog {
  id: string;
  serviceNetworks: string[];
  vendor: string;
}

export interface RepairCatalogItemDefinition {
  id: string;
  repairCatalogId: string,
  manufacturer: string,
  model: string,
  serviceName: string,
  serviceSku: string,
  deviceTypeId: string,
  repairTypeId: string,
  vendor: string,
  color: string,
  partIds: string[]
}

export interface InventoryItem {
  id: string;
  itemType: string;
  catalog: string;
  description: string;
  replacementSkus: ItemSku[];
}

export interface ItemSku {
  sku: string;
  brand: string;
  description: string;
}