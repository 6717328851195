
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Order, FulfillmentTicket, Address, OrderStatusHistory, Enums, } from '@/store/dynamicfulfillment/types';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { jsonToText } from '@/common/functions.helpers';
import DataTable from '@/components/common/DataTable.vue';
import PhoneNumber from '@/components/common/PhoneNumber.vue';
import OrderStatusLabel from '@/components/FulfillmentAuthorization/Orders/OrderStatusLabel.vue';
import { DateTime } from 'luxon';

@Component({
  components: {
    DataTable,
    VueJSONEditor,
    PhoneNumber,
    OrderStatusLabel,
  },
})
export default class FulfillmentTicketOrders extends Vue {
  @Prop() private authorizationId!: string;
  @Prop() private fulfillmentTicket: FulfillmentTicket;
  private jsonEditorText: any = jsonToText;
  private orderFields: any[] = [
    {
      key: 'orderId',
      sortable: true,
    },
    {
      key: 'category',
      sortable: true,
    },
    {
      key: 'serviceType',
      sortable: true,
    },
    {
      key: 'vendor',
      sortable: true,
    },
    {
      key: 'serviceProviderInfo',
      label: 'Service Provider',
      sortable: true,
      formatter: (value: any, key: any, item: any) => {
        if (item && item.serviceProviderInfo) {
          return item.serviceProviderInfo.providerName;
        }
      },
    },
    {
      key: 'externalOrderId',
      sortable: true,
    },
    {
      key: 'total',
      label: 'Total',
      sortable: true,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      formatter: (value: any, key: any, item: any) => {
        return value ?? 0;
      },
    },
    {
      key: 'statusReason',
      label: 'Status Reason',
      sortable: true,
    },
    {
      key: 'statusDate',
      label: 'Status Date',
      sortable: true,
    },
    {
      key: 'details',
    },
  ];

  private get orders() {    
    return this.fulfillmentTicket.orders.map((o: Order) => {
      const latestStatus = o.status?.statusHistory.sort((a: OrderStatusHistory, b: OrderStatusHistory) => a.modifiedDate > b.modifiedDate ? -1 : 1)[0];

      return {
        orderId: o.orderId,
        category: o.category,
        serviceType: o.serviceType,
        vendor: o.vendor,
        serviceProviderInfo: o.serviceProviderInfo,
        serviceNetwork: o.serviceNetwork,
        externalOrderId: o.externalOrderId,
        subtotal: o.subtotal,
        tax: o.tax,
        total: o.total,
        lineItems: o.lineItems,
        billingVendorId: o.billingVendorId,
        status: latestStatus?.status,
        statusReason: latestStatus?.reason,
        statusDate: latestStatus?.modifiedDate,
      }
    });
  }

  private configModal: any = {
    id: '',
    title: '',
    content: '',
  };

  private config(item: any, button: any) {
    this.configModal.id = item.orderId;
    this.configModal.title = `Show JSON`;
    this.configModal.content = item;
    this.$delete(this.configModal.content, '_showDetails');
    this.$root.$emit('bv::show::modal', this.configModal.id, button);
  }

  private resetConfigModal() {
    this.configModal.title = '';
    this.configModal.content = '';
  }
  private formatDateTime(value: any) {
    return value ? DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss') : "";
  }
}
