
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import { ConfigType, DynamicFulfillmentState, Environments, IConfig, IConfigHistory, RouteAction, RoutingAction } from '@/store/dynamicfulfillment/types';
import EventBus from '@/common/event.bus';
import { State } from 'vuex-class';
import PaginationPageSizeControl from '@/components/common/PaginationPageSizeControl.vue';
import FilterOnFields from '@/components/common/FilterOnFields.vue';
import { userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import ConfigHistoryModalComponent from '@/components/configuration/ConfigHistoryModalComponent.vue';
import { buildErrorMessage } from '@/common/functions.helpers';
import DataTable from '@/components/common/DataTable.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ViewContent, FilterOnFields, PaginationPageSizeControl, VueJSONEditor, ConfigHistoryModalComponent, DataTable } })
export default class RemovedConfigIndexComponent extends Vue {
  @Prop() title: string;
  @Prop() indexRoute!: string | { name: string, query?: any };
  @Prop() fields: any[];
  @Prop() initialSort: string;
  @Prop({ default: false }) private sortDesc: boolean;
  @Prop({
    type: Array,
    required: false,
    default: (): any => {
      return ['_attachments'];
    },
  })
  ignoredFields: any[];
  @Prop({ default: false }) isLazyLoad: boolean;
  @Prop({ type: Number, default: 500 }) debounce: number;
  @Prop({ type: Function, default: undefined }) filterFunction: (row: any, filter: string) => boolean;
  @Prop() routeActions: RouteAction[];
  @Prop({default: ConfigType.None}) configType: ConfigType;
  @Prop({required: true}) configItems: IConfig[];
  @State(namespace) profile!: DynamicFulfillmentState;

  filterOnFields: any[] = [];
  configs: IConfig[] = null;
  configsHistory: IConfigHistory[] = null;
  filter: string = '';
  currentPage = 1;
  pageSize = 10;
  stickyHeaderSize = '625px';
  totalRows = 1;
  sortBy = '';
  isFetchingAdditionalRows: boolean = false;
  sortByDesc: boolean = false;
  routingAction = RoutingAction;
  currentPageSize = 10;  
  configHistory: IConfigHistory = {
    _etag: null,
    id: '',
    history: [],
  };  

  async mounted() {
    this.sortByDesc = this.sortDesc;
    await this.load();
  }

  async load() {
    this.configs = this.configItems;
    this.totalRows = this.configs.length;
    this.sortBy = this.initialSort;
    this.isFetchingAdditionalRows = false;
    this.$emit('pageLoaded', this.configs);
  }

  getAction(name: string): RouteAction {    
    return this.routeActions.find((i) => i.name === name);
  }

  onFiltered(filteredItems: IConfig[]) {
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  get allFields() {
    const fields = [...this.fields];
    if (this.hasUserAuditHistory) {
      fields.push(
        {
          key: 'userAudit.current.name',
          label: 'Last Modified User',
          sortable: true,
          filterable: true,
        },
        {
          key: 'userAudit.current.modifiedOnUtc',
          label: 'ModifiedOn Utc',
          sortable: true,
          filterable: true,
          formatter: (value: any, key: any, item: any) => {
            return value === '0001-01-01T00:00:00' ? '' : value;
          },
        },
      );
    }

    fields.push('actions');

    return fields;
  }

  get hasUserAuditHistory() {
    return this.configs.some((x) => x.userAudit);
  }

  async deleteConfig(config: any) {
    this.$bvModal
      .msgBoxConfirm('Are you sure? This will delete the config permanently.', {
        title: ``,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          const deleteAction = this.getAction(RoutingAction.Delete);         
          let params = null;
          if(deleteAction?.params) { 
            deleteAction.params.config = config; 
            params = deleteAction.params;
          } else {
            params = config;
          }
          await this.$store.dispatch(deleteAction.action, params)
          .then(async () => {
            this.configs = this.configs.filter((c) => c.id !== config.id);
            EventBus.$emit('s', `${config.id} deleted successfully`);
          })
          .catch((error: any) => {
              this.displayError(error);
            });       
        }
      })
      .catch((err) => {
        // An error occurred
        this.displayError(err);
      });
  }

  canAction(name: string, id?: string): boolean {
    const routeAction = this.routeActions?.find((routeAction) => routeAction.name === name);

    if (routeAction) {
      const hasPermission = userAuthorizationProviderFactory(this.profile).hasPermission([routeAction.requiredPermission]);

      if (!!id && !!routeAction.exclusion) {
        return !routeAction.exclusion(id) && hasPermission;
      }

      return hasPermission;
    }

    return false;
  }

  filterOnFieldsChanged(filterOnFields: string[]) {
    this.$emit('filterOnFieldsChanged', filterOnFields);
  }

  @Watch('isLazyLoad', { immediate: false })
  async fetchAdditionalRecords() {
    this.isFetchingAdditionalRows = true;
    await this.load();
  }

  @Watch('pageSize', { immediate: true })
  updatePageSize(newValue: any, oldValue: any) {
    this.pageSizeChanged(newValue);
  }

  pageSizeChanged(pageSize: any) {
    this.currentPageSize = pageSize;
  }

  routeResolver(route: string | {}) {
    return typeof route === 'string' ? {name: route} : route;
  }

  isValidEnvironment(): boolean {
    return process.env.VUE_APP_ENVIRONMENT == Environments.LocalHost || process.env.VUE_APP_ENVIRONMENT == Environments.Development || process.env.VUE_APP_ENVIRONMENT == Environments.Model; 
  }

  isEligibleToView(): boolean {
    return userAuthorizationProviderFactory(this.profile).hasAdminRole() && this.isValidEnvironment();
  }

  displayError(error: any) {
      const message = buildErrorMessage(error);
      EventBus.$emit('e', `Request failed. ${message}`);
  }

} 
