
import Multiselect from 'vue-multiselect';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import {
  AllowedAttribute,
  ServiceNetworkConfig,
  ServiceCenterLocationConfig,
  ServiceManufacturer,
  CustomAttributeFields,
  CustomAttributeSchema,
  ServiceLocationAddress,
  ServiceAreaClassification,
  ServiceAreaValueInputType,
} from '@/store/globalmodules/types';
import {
  FETCH_SERVICENETWORK_CONFIGS,
  FETCH_SERVICENETWORK_CONFIG,
  FETCH_SERVICE_MANUFACTURERS
} from '@/store/globalmodules/constants';
import { FETCH_COUNTRY_CONFIGS } from '@/store/dynamicfulfillment/constants';
import { Action, State } from 'vuex-class';
import StoreHour from '../common/StoreHour.vue';
import VueFormGenerator from 'vue-form-generator';
import ServiceAreaComponent from './ServiceAreaComponent.vue';
import { EquipmentType, DynamicFulfillmentState, CountryConfig, AllowedValue, AddressComponents, } from '@/store/dynamicfulfillment/types';
import { DateTime } from 'luxon';
import { ValidationObserver, ValidationObserverInstance, ValidationProvider } from 'vee-validate';
const namespace: string = 'globalmodule';

@Component({
  components: { ViewContent, Multiselect, StoreHour, ServiceAreaComponent, ValidationObserver, ValidationProvider },
})
export default class ServiceCenterLocation extends Vue {
  @Prop() pageTitle!: string;
  @Prop() isEdit: boolean;
  @Prop() config: ServiceCenterLocationConfig;
  @Prop({default: false}) isConfigError: boolean;
  @Action(FETCH_SERVICENETWORK_CONFIGS, { namespace })
  fetchServiceNetworkConfigs: any;
  @Action(FETCH_SERVICE_MANUFACTURERS, { namespace })
  fetchServiceManufacturers: any;
  @Action('dynamicfulfillmentmodule/' + FETCH_COUNTRY_CONFIGS) 
  fetchCountryConfigs: (() => Promise<CountryConfig[]>);
  @State('dynamicfulfillmentmodule') dfprofile!: DynamicFulfillmentState;

  allServiceNetworkConfigs: ServiceNetworkConfig[] = [];
  activeServiceNetworkConfig: ServiceNetworkConfig = null;
  isValidState: boolean = false;
  days: any[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  selectedDays: any[] = [];
  allServiceManufacturers: ServiceManufacturer[] = [];
  allServiceMakeDisplayList: any[] = [];
  selectedMakes: any[] = [];
  selectedServicedEquipmentTypes: any[] = [];
  networkConfigSchema: { [key: string]: CustomAttributeFields } = {};
  vfgFormOptions: any = {
    validateAfterLoad: true,
    validateAfterChanged: true,
    validateAsync: true
  }
  isStoreHoursAvailable: boolean = true;
  active: string = 'information';
  information: boolean = false;
  address: boolean = false;
  serviceArea: boolean = false;
  additionalDetails: boolean = false;
  informationError: string = null;
  addressError: string = null;
  additionalDetailsError: string = null;
  serviceAreaError: string = null;
  tempCountryConfig: CountryConfig = null;

  async created() {    
    this.allServiceNetworkConfigs = await this.fetchServiceNetworkConfigs();
    this.buildCustomAttributeSchema();
    this.fetchCountryConfigs();
    if (this.isEdit) {
      // Ensure required attributes are loaded when editing a service center
      // regardless of whether the currently saved doc contains it
      this.config.isEdit = true;
      this.activeServiceNetworkConfig = this.allServiceNetworkConfigs.find(
        (s) => s.id === this.config.serviceNetwork,
      );
    } else {
      this.config.address.country = this.countryConfig.countryCodeIso2; //set the default country in the drop down.
    }
  }

  get noCustomAttributes() {
    this.isValidState = true;
    return `No Custom Attributes found for ${this.config.serviceNetwork}`;
  }

  async setDone(id: any, index: any) {
    switch (id) {
      case 'information':
        this.informationError = null;
        break;
      case 'address':
        const isValid = await (this.$refs.observer as ValidationObserverInstance).validate();
        if (!isValid) {          
          this.active = 'address';
          return;
        }
        this.addressError = null;
        break;
    }
    if (index) {
      this.active = index;
    }
  }

  buildCustomAttributeSchema() {
    if (this.isEdit) {
      const nConfig = this.allServiceNetworkConfigs.find((networkConfig: ServiceNetworkConfig) => networkConfig.id === this.config.serviceNetwork);
      if (nConfig) {
        this.buildSchema(nConfig);
        this.config.customAttributes = JSON.parse(JSON.stringify(this.config.customAttributes)); //customAttributes are returned as observer instead of object. So parsing back to json.
        VueFormGenerator.schema.mergeMultiObjectFields(this.networkConfigSchema[this.config.serviceNetwork], [this.config.customAttributes]); //map model to schema
      }
    } else {
      if(!this.activeServiceNetworkConfig && this.config.serviceNetwork) {
          this.activeServiceNetworkConfig = this.allServiceNetworkConfigs.find(
          (s) => s.id === this.config.serviceNetwork,
        );
      }      
      this.allServiceNetworkConfigs.forEach((networkConfig: ServiceNetworkConfig) => this.buildSchema(networkConfig));
    }    
  }

  buildSchema(networkConfig: ServiceNetworkConfig) {
    const customAttributeFields: CustomAttributeFields = { fields: [], };
    networkConfig.allowedAttributes.forEach((attribute: AllowedAttribute) => {
      const schema: CustomAttributeSchema = {
        inputType: this.getSchemaInputType(attribute.dataType, attribute.allowMultiple, attribute.allowedValues),
        type: this.getSchemaType(attribute.dataType, attribute.allowMultiple, attribute.allowedValues),
        validator: this.getValidator(attribute.dataType, attribute.allowMultiple, attribute.isRequired, attribute.defaultValue),
        set: (model: any, value: any) => {
          if (attribute.allowMultiple && (!attribute.allowedValues || attribute.allowedValues.length === 0)) {
            model[(attribute.key)] = value.split('\n');
          }
          else {
            model[(attribute.key)] = value;
          }
        },
        get: (model: any) => {
          if (attribute.allowMultiple && (!attribute.allowedValues || attribute.allowedValues.length === 0)) {
            if (Array.isArray(model[(attribute.key)])) {
              return model[(attribute.key)].join('\n');
            }
            else if (attribute.allowMultiple && (!attribute.allowedValues || attribute.allowedValues.length === 0)) {
              if (!Array.isArray(model[(attribute.key)])) {                  
                let values: any[] = [];
                values.push(model[(attribute.key)]);
                return values.join('\n');
              }
              return model[(attribute.key)].join('\n');
            }
            else {
              return model[(attribute.key)];
            }
          }
          else if (attribute.allowMultiple && attribute.allowedValues && attribute.allowedValues.length > 0) {              
            if (!Array.isArray(model[(attribute.key)])) {
              let values: any[] = [];
              values.push(model[(attribute.key)]);
              return values.join(',');
            } else {
              return model[(attribute.key)];
            }
          }
          else {
            return model[(attribute.key)];
          }
        },
        id: attribute.displayName,
        label: attribute.displayName,
        model: attribute.key,
        required: attribute.isRequired,
        default: this.getDefaultValue(attribute.dataType, attribute.defaultValue),
        hint: attribute.description,
        multi: attribute.allowMultiple,
        values: (attribute.allowedValues && attribute.allowedValues.length > 0) ? () => { return attribute.allowedValues } : attribute.allowedValues,        
        textOn: 'Yes',
        textOff: 'No',
        selectOptions: (attribute.allowedValues && attribute.allowedValues.length > 0) ? {
          multiple: attribute.allowMultiple,
          allowEmpty: !attribute.isRequired,
          maxHeight: 100,
        } : null,
        attributes: {
          "trim": attribute.dataType === "String" ? true : null,
        },
      };
      customAttributeFields.fields.push(schema);
      if(this.isEdit && attribute.allowMultiple && !this.config.customAttributes[attribute.key]) { 
        //Adding the field back to the model if the attribute is allowMultiple, because vue-multiselect does not retain selected option if there is no field in the model
         this.config.customAttributes[attribute.key] = '';
      }
      /* This is to add a required boolean field with default value back to the model if the required boolean attribute not present in the model. 
        This could happen when an attribute is added to Network Config after the location has been created using that Network Config. */
      if(this.isEdit && attribute.dataType === 'Boolean' && attribute.isRequired && this.config.customAttributes[attribute.key] === undefined) {
         this.config.customAttributes[attribute.key] = attribute.defaultValue ?? false;
      }
    });
    Object.assign(this.networkConfigSchema, { [networkConfig.id]: customAttributeFields });
  }

  isString(dataType: string) {
    return dataType === 'String';
  }

  isBoolean(dataType: string) {
    return dataType === 'Boolean';
  }

  isInteger(dataType: string) {
    return dataType === 'Integer';
  }

  isDate(dataType: string) {
    return dataType === 'Date';
  }

  isDecimal(dataType: string) {
    return dataType === 'Decimal';
  }

  getSchemaType(dataType: string, allowMultiple: boolean, allowedValues: any[]) {
    if (allowedValues && allowedValues.length > 0) {
      return 'vueMultiSelect';
    } else if (allowMultiple && ((allowedValues && allowedValues.length === 0) || !allowedValues)) {
      return 'textArea';
    }

    switch (dataType) {
      case 'String':
      case 'Integer':
      case 'Decimal':
        return 'input';
      case 'Boolean':
        return 'switch';
      case 'Date':
        return 'vfj-datepicker';
      default:
        return 'input';
    }
  }

  getSchemaInputType(dataType: string, allowMultiple: boolean, allowedValues: any[]) {
    if (allowMultiple && allowedValues && allowedValues.length > 0) return null;
    switch (dataType) {
      case 'String':
        return 'text';
      case 'Integer':
        return 'number';
      case 'Decimal':
        return 'text';
      default:
        return null;
    }
  }

  getValidator(dataType: string, allowMultiple: boolean, isRequired: boolean, defaultValue: any) {
    let validators: string[] = [];
    if (isRequired && !(dataType === 'Boolean' && defaultValue !== undefined)) validators.push("required");
    if (allowMultiple) validators.push("uniqueValueValidator");
    switch (dataType) {
      case 'Integer':
      case 'Decimal':
        validators.push("numberValidator");
        break;
      default:
        return validators;
    }
    return validators;
  }

  getDefaultValue(dataType: string, defaultValue: any) {
    return (dataType === 'Boolean' && !defaultValue) ? false : (!defaultValue ? '' : defaultValue);
  }

  getSchema(networkConfigId: string) {
    return this.networkConfigSchema[networkConfigId].fields;
  }

  onValidated(isValid: boolean, errors: any) {
    this.isValidState = isValid;
    this.$emit('setFormState', this.isValidState);
  }

  get isValidStoreInformation() {
    if (!this.config.storeId || this.config.storeId === '') return false;
    if (!this.config.name || this.config.name === '') return false;
    if (!this.config.name || this.config.name === '') return false;
    return true;
  }

  cleanConfig() {
    const networkConfig: ServiceNetworkConfig = this.allServiceNetworkConfigs.filter((nConfig: ServiceNetworkConfig) => nConfig.id === this.config.serviceNetwork)[0];    
    const notRequiredFields: string[] = [];
    const allowMultipleFields: { [key: string]: {allowedValues: any[]} } = {};
    if (!networkConfig) return;
    networkConfig.allowedAttributes.filter((a: AllowedAttribute) => a.isRequired === false).forEach((iField: AllowedAttribute) => {
      notRequiredFields.push(iField.key);
    });
    networkConfig.allowedAttributes.filter((a: AllowedAttribute) => a.allowMultiple === true).forEach((iField: AllowedAttribute) => {
      allowMultipleFields[iField.key] = { allowedValues: iField.allowedValues};
    });   

    Object.keys(this.config.customAttributes).forEach((key: string) => {
      const value = JSON.parse(JSON.stringify(this.config.customAttributes[key]));
      //remove key when there is no value for the not required attributes
      if (notRequiredFields.includes(key) && (value === null || (!Array.isArray(value) && value === '') || Array.isArray(value) && value.filter((str: any) => str !== '').length === 0)) {
        delete this.config.customAttributes[key];
      } else {
        /*When the allowed values in the network config is changed but the location config can still have old values stored. 
        So if the user tries to update the location config with the new allowed values, the old values are also included. 
        So we need to check for allowMultiple attributes, that we are sending only the values contained in the allowed values list.
        */
        const allowMultipleField = allowMultipleFields[key];
        if(allowMultipleField && allowMultipleField.allowedValues.length > 0) {
          const values = this.config.customAttributes[key];
          const updatedValues: any[] = [];
          if (values && Array.isArray(values)) {
            values.forEach( (value: any, index: number) => {
              if (allowMultipleField.allowedValues.includes(value)) {
                updatedValues.push(value);
              }
            });
            this.config.customAttributes[key] = updatedValues;  
          } else {
            this.config.customAttributes[key] = [values];
          }      
        } else if (allowMultipleField && (!allowMultipleField.allowedValues || (allowMultipleField.allowedValues && allowMultipleField.allowedValues.length === 0))) {
          const values = this.config.customAttributes[key];
          if (Array.isArray(values)) {
            this.config.customAttributes[key] = values.filter((str: any) => str !== '');
          } else {
            this.config.customAttributes[key] = [values];
          }   
        }
    }
    });
  }

  submit(e: any) {
    if (!this.isValidStoreInformation) {
      this.informationError = "Required fields missing!";
      this.active = 'information';
      setTimeout(() => {
        (this.$refs.formInformationSubmit as any).click();
      }, 500);
    } else {
      this.cleanConfig();
      this.$emit('submitForm', e);
    }
  }

  @Watch('isConfigError', {immediate: false}) 
  configError() {
    const nConfig = this.allServiceNetworkConfigs.find((networkConfig: ServiceNetworkConfig) => networkConfig.id === this.config.serviceNetwork);
    nConfig.allowedAttributes.forEach((attribute: AllowedAttribute) => {
      if(attribute.allowMultiple && !this.config.customAttributes[attribute.key]) { 
        //Adding the field back to the model if the attribute is allowMultiple, because vue-multiselect does not retain selected option if there is no field in the model
         this.config.customAttributes[attribute.key] = '';
      }
    });
    this.config.customAttributes = JSON.parse(JSON.stringify(this.config.customAttributes)); //customAttributes are returned as observer instead of object. So parsing back to json.
    VueFormGenerator.schema.mergeMultiObjectFields(this.networkConfigSchema[this.config.serviceNetwork], [this.config.customAttributes]); //map model to schema
  }

  async mounted() {
    if (!this.isEdit) {
      this.$emit('setFormState', this.isValidState);
    }
    this.allServiceManufacturers = await this.fetchServiceManufacturers();
    this.allServiceManufacturers.forEach((element: ServiceManufacturer) => {
      this.allServiceMakeDisplayList.push({ id: element.id, name: element.displayName })
    });

    if (this.config.servicedManufacturers && this.config.servicedManufacturers.length > 0) {
      this.config.servicedManufacturers.forEach((m: any) => {
        var make = this.allServiceManufacturers.find((x) => x.id == m);
        this.selectedMakes.push({ id: make.id, name: make.displayName });
      });
    } else {
      this.config.servicedManufacturers = [];
    }

    // Set the selected equipment types as per the service center config
    if (this.config.servicedEquipmentTypes && this.config.servicedEquipmentTypes.length > 0) {
      this.config.servicedEquipmentTypes.forEach((et: EquipmentType) => {
        this.selectedServicedEquipmentTypes.push({ name: et });
      });
    } else {
      this.config.servicedEquipmentTypes = [];
    }

    if (this.config.openHours.length === 0) {
      if (!this.isEdit) {
        // On Add, if no existing openHours found, set default values
        this.days.forEach((d: any) => {
          this.config.openHours =
            this.config.openHours.concat({
              day: d,
              startTime: DateTime.fromFormat('08:00', 'HH:mm').toFormat('t'),
              endTime: DateTime.fromFormat('17:00', 'HH:mm').toFormat('t'),
              startTimeFormatted: DateTime.fromFormat('08:00', 'HH:mm').toFormat('HH:mm'),
              endTimeFormatted: DateTime.fromFormat('17:00', 'HH:mm').toFormat('HH:mm'),
              isValid: true,
            });
        });
      } else {
        // On Edit and service location has no existing openHours, clear values
        this.isStoreHoursAvailable = false;
        this.isStoreHoursAvailableChanged();
      }
    }

    this.config.openHours.forEach((o: any) => {
      this.selectedDays.push(o.day);
    });
  }

  get isValidStoreHours() {
    if (
      this.config.openHours &&
      this.config.openHours.length > 0 &&
      this.isStoreHoursAvailable
    ) {
      return true;
    }
    return false;
  }

  @Watch('config.openHours', {
    deep: true,
    immediate: false,
  })
  storeTimings_Changed(value: any) {
    this.config.openHours.forEach((s: any) => {
      if (!s.isValid) {
        this.isValidState = false;
      } else {
        this.isValidState = true;
        s.startTime = s.startTimeFormatted
          ? DateTime.fromISO(s.startTimeFormatted).toFormat('hh:mm a')
          : s.startTime;
        s.endTime = s.endTimeFormatted
          ? DateTime.fromISO(s.endTimeFormatted).toFormat('hh:mm a')
          : s.endTime;
      }
    });
    this.$emit('setFormState', this.isValidState);
  }

  isStoreHoursAvailableChanged() {
    if (!this.isStoreHoursAvailable) {
      // Clear open hours when store hours are not required
      this.config.openHours = [];
    } else {
      // Set default hours when switching from no store hours required
      this.selectedDays = [];
      this.days.forEach((d: any) => {
        this.config.openHours =
          this.config.openHours.concat({
            day: d,
            startTime: DateTime.fromFormat('08:00', 'HH:mm').toFormat('t'),
            endTime: DateTime.fromFormat('17:00', 'HH:mm').toFormat('t'),
            startTimeFormatted: DateTime.fromFormat('08:00', 'HH:mm').toFormat('HH:mm'),
            endTimeFormatted: DateTime.fromFormat('17:00', 'HH:mm').toFormat('HH:mm'),
            isValid: true,
          });
        this.selectedDays.push(d);
      });
    }
  }

  @Watch('config.serviceNetwork', {
    deep: true,
    immediate: false,
  })
  serviceNetwork_Changed(serviceNetwork: any) {
    if (!this.isEdit) {
      // When creating a new ServiceCenter, update allowed custom attributes when service network changes
      this.loadRequiredCustomAttributesFromNetworkConfig(serviceNetwork);
    }
  }

  get eligibleMakes() {
    return this.allServiceMakeDisplayList.sort((a, b) => {
      return a.name < b.name ? -1 : 1
    });
  }

  eligibleMake(val: any) {
    return val.name;
  }

  get servicedEquipmentTypes() {
    // Initialize the equipment types options list and sort
    var allEquipmentTypesDisplayList : any[] = [];
    Object.entries(EquipmentType)
          .filter((e: any) => isNaN(e[0] as any))
          .map((e: any) => e[0])
          .forEach(element => {
            allEquipmentTypesDisplayList.push({ name: element });});
    return allEquipmentTypesDisplayList.sort((a, b) => {
      return a.name < b.name ? -1 : 1
    });
  }

  servicedEquipmentType(val: any) {
    return val.name;
  }

  @Watch('selectedDays', { deep: true, immediate: false })
  updateDays(value: any) {
    //Remove if any day is removed
    this.config.openHours.forEach((d: any, index) => {
      if (!this.selectedDays.some((day) => day === d.day)) {
        this.config.openHours.splice(index, 1);
      }
    });
    //Add if any newly added days in selectedDays
    this.selectedDays.forEach((d: any, index) => {
      if (
        !this.config.openHours.some(
          (data: any) => data.day === d,
        )
      ) {
        // If config.serviceLocationData.openHours doesn't contain a selected day, add it to config
        this.config.openHours =
          this.config.openHours.concat({
            day: d,
            startTime: DateTime.fromFormat('08:00', 'HH:mm').toFormat('t'),
            endTime: DateTime.fromFormat('17:00', 'HH:mm').toFormat('t'),
            startTimeFormatted: DateTime.fromFormat('08:00', 'HH:mm').toFormat('HH:mm'),
            endTimeFormatted: DateTime.fromFormat('17:00', 'HH:mm').toFormat('HH:mm'),
            isValid: true,
          });
      }
    });
    this.config.openHours.sort((a, b) => {
      return this.days.indexOf(a.day) - this.days.indexOf(b.day);
    });
  }

  get isNew() {
    return this.config.eTag === null;
  }

  remove(items: any[], item: any) {
    const index = items.indexOf(item);
    items.splice(index, 1);
  }

  loadRequiredCustomAttributesFromNetworkConfig(serviceNetwork: any) {
    this.activeServiceNetworkConfig = this.allServiceNetworkConfigs.find(
      (s) => s.id === serviceNetwork,
    );
    if(this.networkConfigSchema[serviceNetwork]) {
      this.config.customAttributes = VueFormGenerator.schema.createDefaultObject(this.networkConfigSchema[serviceNetwork]);
    }    
  }

  hasAllowedValues(attributeKey: string) {
    var attribute = this.activeServiceNetworkConfig?.allowedAttributes.filter(
      (x) => x.key == attributeKey,
    )[0];
    return attribute?.allowedValues && attribute?.allowedValues.length > 0;
  }

  getAllowedValues(attributeKey: string) {
    var attribute = this.activeServiceNetworkConfig?.allowedAttributes.filter(
      (x) => x.key == attributeKey,
    )[0];
    return attribute?.allowedValues;
  }

  @Watch('selectedMakes', { deep: true, immediate: false })
  updateMakes(value: any) {
    //Remove if any make is removed
    this.config.servicedManufacturers.forEach((m: any, index) => {
      if (!this.selectedMakes.some((make) => make.id === m)) {
        this.config.servicedManufacturers.splice(index, 1);
      }
    });
    //Add if any newly make in selectedMakes
    this.selectedMakes.forEach((m: any, index) => {
      if (
        !this.config.servicedManufacturers.some(
          (data: any) => data === m.id,
        )
      ) {
        // If config.serviceLocationData.servicedManufacturers doesn't contain a selected make, add it to config
        this.config.servicedManufacturers =
          this.config.servicedManufacturers.concat(m.id);
      }
    });
  }

  @Watch('selectedServicedEquipmentTypes', { deep: true, immediate: false })
  updateEquipmentTypes(value: any) {
    //Remove if any EquipmentType is removed
    this.config.servicedEquipmentTypes.forEach((et: any, index) => {
      if (!this.selectedServicedEquipmentTypes.some((equipmentType) => equipmentType.name === et)) {
        this.config.servicedEquipmentTypes.splice(index, 1);
      }
    });
    //Add if any new selections in selectedServicedEquipmentTypes
    this.selectedServicedEquipmentTypes.forEach((et: any, index) => {
      if (
        !this.config.servicedEquipmentTypes.some(
          (data: any) => data === et.name,
        )
      ) {
        // If config.servicedEquipmentTypes doesn't contain a selected equipment type, add it to config
        this.config.servicedEquipmentTypes =
          this.config.servicedEquipmentTypes.concat(et.name);
      }
    });
  }

  addServiceArea() {
    this.config.serviceArea.push( { classification: ServiceAreaClassification[ServiceAreaClassification.PostalCode], inputType: ServiceAreaValueInputType[ServiceAreaValueInputType.Exact], value: ''});
  }

  serviceAreaCollectionChange(context: { config: ServiceCenterLocationConfig, isValid: boolean}) {
    this.$emit('onConfigChange', context);
  }

  @Watch('config.address.country', { deep: true, immediate: false }) 
  onCountryChanged(){
    this.countryConfig = this.dfprofile.countryConfigs.find((c: CountryConfig) => c.countryCodeIso2.toLowerCase() === this.config.address.country.toLowerCase() || c.countryCodeIso3.toLowerCase() === this.config.address.country.toLowerCase());
  }

  get countryConfig() {
    if(!this.isEdit) {
      return this.tempCountryConfig ? this.tempCountryConfig : this.dfprofile.defaultCountryConfig;
    }
    return this.dfprofile.countryConfigs.find((c: CountryConfig) => c.countryCodeIso2.toLowerCase() === this.config.address.country.toLowerCase() || c.countryCodeIso3.toLowerCase() === this.config.address.country.toLowerCase());    
  }
  
  set countryConfig(value: CountryConfig) {
    this.tempCountryConfig = value;
  }

  get countryCodes() {
    return this.dfprofile.countryConfigs.map(config => {
      return {
        countryCodeIso2: config.countryCodeIso2,
        countryName: config.countryName
      };
    });
  }

  get regionAllowedValues() {
    return this.countryConfig?.addressComponents.region.allowedValues;
  }

  isFormGroupValid(formGroupName: keyof AddressComponents) : boolean {
    const formGroup = this.countryConfig?.addressComponents[formGroupName];
    if (!formGroup) {
      return true;
    }
    const value = this.config.address[formGroupName];
    if (formGroup.required && !value) {
      return false;
    }
    if (formGroup.validationRegex && !new RegExp(formGroup.validationRegex).test(value)) {
      return false;
    }
    return true;
  }
}
