
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import FulfillmentOptionVendorConfigListCreateEdit from '@/components/configuration/FulfillmentOptionVendorConfigs/FulfillmentOptionVendorConfigListCreateEdit.vue';
import {
  FulfillmentOptionConfig,
  FulfillmentOptionUserInterfaceSettings,
  FulfillmentOption,
  DynamicFulfillmentState,
  Peril,
} from '@/store/dynamicfulfillment/types';
import { State } from 'vuex-class';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { jsonToText } from '@/common/functions.helpers';
import DataTable from '@/components/common/DataTable.vue';

@Component({
  components: { FulfillmentOptionVendorConfigListCreateEdit, VueJSONEditor, DataTable },
})
export default class FulfillmentOptionConfigCreateEdit extends Vue {
  @Prop() private config: FulfillmentOptionConfig;
  @State('dynamicfulfillmentmodule') private profile!: DynamicFulfillmentState;
  private jsonEditorText: any = jsonToText;

  private get isNew() {
    return this.config._etag === null;
  }

  private descriptionItems(settings: FulfillmentOptionUserInterfaceSettings) {
    return settings.descriptionItems.map((item: string) => {
      return {
        descriptionItemText: item,
      };
    });
  }

  private get allowedPerils() {
    const perils = [] as number[];
    var perilNames = Object.keys(Peril).filter(key => !isNaN(Number(Peril[<any>key])));
    for (var peril in perilNames) {
      if (peril === '0') {
        continue;
      }
      perils.push(Number.parseInt(peril, 10));
    }
    return perils;
  }

  private get allowedFulfillmentOptions() {
    return Object.entries(FulfillmentOption)
      .filter((e: any) => !isNaN(e[0] as any))
      .map((e: any) => e[0]);
  }

  private get allowedFulfillmentOptionCategories() {
    return Object.keys(this.profile.fulfillmentOptionCategories);
  }

  private fulfillmentOptionLabel(option: number) {
    return FulfillmentOption[option];
  }

  private fulfillmentOptionCategoryLabel(option: number) {
    return this.profile.fulfillmentOptionCategories[option];
  }

  private perilLabel(peril: number) {
    return Peril[peril];
  }

  private addDescriptionItem(config: FulfillmentOptionConfig) {
    return config.userInterfaceSettings.descriptionItemsShadow.push({} as any);
  }

  private deleteDescriptionItem(config: FulfillmentOptionConfig, item: any) {
    this.remove(config.userInterfaceSettings.descriptionItemsShadow, item);
  }

  private remove(items: any[], item: any) {
    const index = items.indexOf(item);
    items.splice(index, 1);
  }

  onConfigChange(request: { config: FulfillmentOptionConfig | null; isValid: boolean }) {
    this.$emit('onConfigChange', request);
  }

  @Watch('config.defaultSlaDuration', { immediate: true, deep: true })
  private setDefaultSlaDuration() {
    if (this.config.defaultSlaDuration == '') {
      this.config.defaultSlaDuration = '00:00:00';
    }
    var sla = this.config.defaultSlaDuration.split(':');
    if (sla.length < 2) {
      this.config.defaultSlaDuration = sla[0] + ':00:00';
    } else if (sla.length < 3) {
      this.config.defaultSlaDuration = sla[0] + ':' + sla[1] + ':00';
    }
  }

  @Watch('config', { immediate: false, deep: true })
  private config_changed() {
    this.$emit('stateUpdate');
  }

  private userInterfaceSettingsChanged(event: any) {
    this.config.userInterfaceSettings.settings = JSON.parse(event.text);
  }
}
