
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import DataTable from '@/components/common/DataTable.vue';
import { Action } from 'vuex-class';
import { FulfillmentAuthorizationWidget, FulfillmentOption, SourceSystem, FulfillmentTicketStatus } from '@/store/dynamicfulfillment/types';
import { FETCH_RECENT_AUTHORIZATIONS } from '@/store/dynamicfulfillment/constants';
import FulfillmentTicketStatusLabel from '@/components/FulfillmentAuthorization/FulfillmentTicketStatusLabel.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { DataTable, FulfillmentTicketStatusLabel } })
export default class RecentAuthorizations extends Vue {
  @Prop() private isReload: boolean;
  @Prop() private isFullScreen: boolean;
  private minPageSize: number = 9;
  private maxPageSize: number = 25;
  private pageSize: number = 9;
  private authCount: number = 100;
  private fields = [
    {
      key: 'source',
      sortable: true,
      label: 'Source',
      formatter: (value: any, key: any, item: any) => {
        return SourceSystem[value];
      },
      type: SourceSystem,
    },
    {
      key: 'claimId',
      sortable: true,
      label: 'Claim Number',
    },
    {
      key: 'clientId',
      sortable: true,
      label: 'Client',
    },
    {
      key: 'fulfillmentOption',
      sortable: true,
      label: 'Fulfillment Option',
      formatter: (value: any, key: any, item: any) => {
        return FulfillmentOption[value];
      },
      type: FulfillmentOption,
    },
    {
      key: 'vendorId',
      sortable: true,
      label: 'Vendor',
    },
    {
      key: 'authorizationId',
      sortable: true,
      label: 'Authorization Id',
    },
    {
      key: 'fulfillmentTicketStatus',
      label: 'Ticket Status',
      type: FulfillmentTicketStatus,
    },
    {
      key: 'flowType',
      label: 'Flow Type',
    },
  ];
  private auths: FulfillmentAuthorizationWidget[] = null;
  @Action(FETCH_RECENT_AUTHORIZATIONS, { namespace }) private fetchRecentAuths: any;

  public async mounted() {
    this.getAuthorizations();
  }

  private async getAuthorizations() {
    this.auths = await this.fetchRecentAuths(this.authCount);
    this.$emit('onReloadCompleted');
  }

  private sourceSystem(source: number) {
    return SourceSystem[source];
  }

  @Watch('isReload', { immediate: false })
  private refresh() {
    this.getAuthorizations();
  }

  @Watch('isFullScreen', { immediate: false })
  private onFullScreen() {
    this.pageSize = this.isFullScreen ? this.maxPageSize : this.minPageSize;
  }
}
