
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FulfillmentTicket } from '@/store/dynamicfulfillment/types';
import { GET_SVS_ORDER_BY_ID } from '@/store/dynamicfulfillment/constants';
import { Action } from 'vuex-class';
import ListItem from '@/components/common/ListItem.vue';
import { DateTime } from 'luxon';
import DataTable from '@/components/common/DataTable.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ListItem, DataTable } })
export default class SvsGiftCardReplacementsDetail extends Vue {
  @Prop() private fulfillmentTicket!: FulfillmentTicket;
  @Action(GET_SVS_ORDER_BY_ID, { namespace }) private getSvsOrderById: any;
  private orderDetails: any = null;
  private svsOrderCardFields: any[] = [
    { key: 'id', label: 'Card Id' },
    { key: 'amount', label: 'Card Amount' },
    { key: 'status', label: 'Reconciliation Status' },
    'details',
  ];
  private svsOrderCardHistoryFields: any[] = [
    { key: 'activityDate', label: 'Activity Date' },
    { key: 'cardNumber', label: 'Card Number' },
    { key: 'status', label: 'Reconciliation Status' },
  ];

  public async mounted() {
    this.orderDetails = await this.getSvsOrderById(this.fulfillmentTicket.bookedOfferConfirmationId);
  }

  private formatDateTime(value: any) {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }
}
