
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  FulfillmentTicket,
  FulfillmentOption,
  FulfillmentOptionConfig,
  VendorStatusEvent,
  FulfillmentTicketStatus as FulfillmentTicketStatusEnum,
  DynamicFulfillmentState,
  VendorConfig,
  Enums,
} from '@/store/dynamicfulfillment/types';
import FulfillmentTicketStatus from '@/components/FulfillmentAuthorization/FulfillmentTicketStatusLabel.vue';
import ListItem from '@/components/common/ListItem.vue';
import {
  SEARCH_FULFILLMENT_TICKET_BY_AUTH,
  GET_FULFILLMENT_OPTION_CONFIG,
  CANCEL_VENDOR_OFFER,
  FETCH_VENDOR_STATUS_EVENTS,
  FETCH_FULFILLMENT_OPTION_CONFIGS,
  RESTART_FULFILLMENT_TICKET_ORCHESTRATION,
  FETCH_VENDOR_CONFIG,
} from '@/store/dynamicfulfillment/constants';
import OrchestrationHistory from '@/components/FulfillmentAuthorization/OrchestrationHistory.vue';
import EventBus from '@/common/event.bus';
import { copyToClipboard } from '@/common/utilities';
import UpdateTicketStatus from '@/components/FulfillmentAuthorization/UpdateTicketStatus.vue';
import ViewContent from '@/components/ViewContent.vue';
import DataTable from '@/components/common/DataTable.vue';
import { UserAuthorizationProvider, userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import { Action, State } from 'vuex-class';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { buildErrorMessage, jsonToText, removeCosmosSystemProperties } from '@/common/functions.helpers';
import { vendorDetailFactory } from '@/common/vendorDetailFactory';
import BookOfferAttempts from '@/components/FulfillmentAuthorization/BookOfferAttempts.vue';
import FulfillmentTicketInvoices from '@/components/FulfillmentAuthorization/FulfillmentTicketInvoices.vue';
import FulfillmentTicketAuthorizedAmountDetails from '@/components/FulfillmentAuthorization/FulfillmentTicketAuthorizedAmountDetails.vue';
import FulfillmentTicketOrders from '@/components/FulfillmentAuthorization/Orders/FulfillmentTicketOrders.vue';
import { DateTime } from 'luxon';
import StatusHistoryComponent from '@/components/FulfillmentAuthorization/StatusHistoryComponent.vue';
import SendVendorTicketStatus from '@/components/FulfillmentAuthorization/SendVendorTicketStatus.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({
  components: {
    FulfillmentTicketStatus,
    ListItem,
    OrchestrationHistory,
    UpdateTicketStatus,
    ViewContent,
    DataTable,
    VueJSONEditor,
    BookOfferAttempts,
    FulfillmentTicketInvoices,
    FulfillmentTicketAuthorizedAmountDetails,
    FulfillmentTicketOrders,
    StatusHistoryComponent,
    SendVendorTicketStatus,
  },
})
export default class FulfillmentTicketDetail extends Vue {
  @Prop() private authorizationId!: string;
  @Prop() private fulfillmentTicketId!: string;
  private fulfillmentTicket: FulfillmentTicket = null;
  private vendorStatusUpdates: VendorStatusEvent[] = null;

  private vendorStatusFields: any[] = [{ key: '_ts', label: 'Status Time', sortable: true }, 'Vendor', {key: 'Payload', label: 'Payload', tdClass: 'payloadCol'}, { key: 'root', label: 'Actions' }];
  private statusHistoryDataFields: any[] = [ 'name', 'value'];
  private jsonEditorText: any = jsonToText;
  private removeCosmosSystemProperties : any = removeCosmosSystemProperties;
  private userAuthorizationProvider: UserAuthorizationProvider;
  @Action(FETCH_VENDOR_CONFIG, { namespace }) private fetchVendorConfig: ((vendorId: string) => Promise<VendorConfig>);
  @Action(SEARCH_FULFILLMENT_TICKET_BY_AUTH, { namespace }) private searchFulfillmentTicketByAuth: any;
  @Action(FETCH_VENDOR_STATUS_EVENTS, { namespace }) private fetchVendorStatusEvents: any;
  @Action(CANCEL_VENDOR_OFFER, { namespace }) private cancelVendorOfferAction: any;
  @Action(RESTART_FULFILLMENT_TICKET_ORCHESTRATION, { namespace }) private restartTicketOrchestration: any;
  @State(namespace) private profile: DynamicFulfillmentState;
  private vendorConfig: VendorConfig = null;
  private statusModal: any = { id: '', title: '', content: '' };

  public async mounted() {
    await this.fetchFulfillmentTicket();

    if (this.hasPermission(['Configuration-Vendors-View'])) {
      this.vendorConfig = await this.fetchVendorConfig(this.fulfillmentTicket.acceptedOfferInfo.offer.vendorId);
    }

    this.checkAncillaryOrders();
  }

  public async fetchFulfillmentTicket() {
    const query = { authorizationId: this.authorizationId, fulfillmentTicketId: this.fulfillmentTicketId };
    this.fulfillmentTicket = await this.searchFulfillmentTicketByAuth(query);
    this.vendorStatusUpdates = await this.fetchVendorStatusEvents(this.fulfillmentTicketId);
  }

  private get canCancelVendorOffer(): boolean {
    return false;
  }

  private canSendVendorStatusUpdate(): boolean {
    if (!this.vendorConfig) {
      return false;
    }

    return this.vendorConfig.statusMapperStrategy === "GenericStatusUpdateMapper";
  }

  private async cancelVendorOffer() {
    try {
      await this.cancelVendorOfferAction(this.fulfillmentTicket);
      EventBus.$emit('s', 'Vendor offer cancelled successfully!');
    } catch (error) {
      let message = 'Vendor offer cancellation failed!\n\n';
      const err: any = error;
      if (err.response && err.response.data) {
        message += JSON.parse(err.response.data.content).message;
      } else {
        message += error.toString();
      }
      EventBus.$emit('e', message);
    }
  }

  private confirmCancelVendorOffer() {
    this.$bvModal.msgBoxConfirm('Are you sure you want to cancel the offer?', {
      title: 'Confirm Vendor Offer Cancellation',
      size: 'lg',
      buttonSize: 'lg',
      okVariant: 'danger',
      okTitle: 'Yes',
      cancelTitle: 'No',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true,
    })
      .then((value) => {
        if (value === true) {
          this.cancelVendorOffer();
        }
      })
      .catch((err) => {
        EventBus.$emit('e', err.toString());
      });
  }

  private copyPayload(payload: any) {
    copyToClipboard(JSON.stringify(payload, null, 2));
  }

  private copyToClipboard(string: string) {
    copyToClipboard(string);
  }

  private openTicketStatusModal() {
    this.$root.$emit('open-update-ticket-status');
  }

  private openSendVendorStatusUpdate() {
    this.$root.$emit('send-vendor-status-update');
  }

  private async ticketStatusUpdated() {
    await this.fetchFulfillmentTicket();
    EventBus.$emit('s', 'Ticket status updated successfully!');
  }

  private async sentVendorTicketStatusUpdate() {
    EventBus.$emit('s', 'Sent Vendor Ticket Status Update successfully!');
  }

  private hasPermission(features: string[]) {
    if (!this.userAuthorizationProvider)
    {
      this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    }
    return this.userAuthorizationProvider.hasPermission(features);
  }

  private resetStatusModal() {
    this.statusModal.title = '';
    this.statusModal.content = '';
  }

  private showStatusModal(statusItem: any, index: any, button: any) {
    this.statusModal.id = statusItem.id;
    this.statusModal.title = `Vendor Status Update Event`;
    this.statusModal.content = removeCosmosSystemProperties(statusItem);
    this.$root.$emit('bv::show::modal', this.statusModal.id, button);
  }

  private get getFulfillmentOptionText() {
    return (val: number) => {
      return FulfillmentOption[val];
    };
  }

  private getVendorDetailComponent() {
    return vendorDetailFactory.GetFulfillmentTicketVendorDetailComponent(this.fulfillmentTicket);
  }

  private getVendorDetailTabName() {
    return this.fulfillmentTicket.acceptedOfferInfo.offer.vendorId + ' Vendor Detail';
  }

  private showVendorDetailTab() {
    return this.getVendorDetailComponent() && this.fulfillmentTicket.bookedOfferConfirmationId
      && this.fulfillmentTicket.claimAuthorizationNumber;
  }

  private formatDateTime(value: any) {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }

  private formatTimeSaved(value: any) {
    return DateTime.fromSeconds(value).toFormat('yyyy-MM-dd HH:mm:ss');
  }

  private get title() {
    return 'Fulfillment Ticket Detail';
  }

  private checkAncillaryOrders() {
    const hasPrimaryOrderFailed = this.fulfillmentTicket.orders.some(order => 
      Enums.OrderCategory[order.category] === 'Primary' && 
      [Enums.OrderStatus.Cancelled, Enums.OrderStatus.Rejected, Enums.OrderStatus.Failed].includes(order.status.current)
      );

      if (hasPrimaryOrderFailed) {
        const hasAncillaryOrderInProgress = this.fulfillmentTicket.orders.some(order => 
        Enums.OrderCategory[order.category] === 'Ancillary' && 
          [Enums.OrderStatus.Pending, Enums.OrderStatus.InProgress, Enums.OrderStatus.Created].includes(order.status.current)
        );

        if (hasAncillaryOrderInProgress) {
          EventBus.$emit('w-noexp', `Fulfillment Ticket contains ancillary orders that are not completed.`);
        }
    }
  }

  private async restartOrchestration() {
    this.restartTicketOrchestration(this.fulfillmentTicketId)
    .then((response: any) =>  {
      EventBus.$emit('s', 'Ticket Orchestration has started successfully.');
    })
    .catch((error: any) => {
      const message = buildErrorMessage(error);
      EventBus.$emit('e', `Restart Orchestration failed! ${message}`);
    });
  }

  private isTicketFailed(): boolean {
    return this.fulfillmentTicket && this.fulfillmentTicket.status.current === FulfillmentTicketStatusEnum.Failed;
  }
  
}
